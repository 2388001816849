<div class="content">
  <div class="card">
    <div class="card-header header-elements-inline" [ngBusy]="busy">
      <h6 class="card-title">Liste des clients</h6>

      <div class="heading-elements">
        <button
          [routerLink]="['/admins/add-client']"
          type="button"
          class="btn btn-info btn-xs"
        >
          <i class="icon-plus2 position-left"></i>Ajouter un client
        </button>
      </div>
    </div>
    <br />

    <div class="card-body">
      <table class="table perso-table" datatable [dtOptions]="dtOptions">
        <thead>
          <tr>
            <th>Nom de l'Entreprise</th>
            <th>Nom du dirigeant</th>
            <!--th>Salarié qui gère le client</th-->
            <th>Téléphone</th>
            <th>Email</th>
            <th>Adresse</th>
            <th>Secteur d'activité</th>
            <th>Siret</th>
            <!--th>IDCC</th>
            <th>Code NAF</th>
            <th>Date de création</th>
            <th>URSSAF Identifiant</th>
            <th>IMPOTS Identifiant</th>
            <th>RETRAITE Identifiant</th>
            <th>PREVOYANCE Identifiant</th>
            <th>MUTUELLE Identifiant</th-->
            <th>Actions</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
  </div>
</div>
