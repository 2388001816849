<div class="content" style="padding-bottom: 0px">
    <!-- Invoice archive -->
    <div class="card" style="margin-bottom: 0px">
      <div class="card-header header-elements-inline">
        <h6 class="card-title">{{mailTypeAdmin?.display_name}}</h6>
      </div>
      <input type="text" style="width: 80%; margin: 10px" class="form-control" placeholder="Objet" [(ngModel)]="object">
      <quill-editor [modules]="{imageResize:true}" (onEditorCreated)="onEditorCreated($event)" [(ngModel)]="template"
                    class="editor"></quill-editor>
      <input type="file" style="display: none" id="fileInputField"/>
    </div>
    <div class="row" style="margin:5px 0px; text-align: center;display: none;">
      <div *ngFor="let item of params">
        <button class="btn btn-secondary mr-1 mt-1 dropdown-toggle"
                aria-expanded="false" aria-haspopup="true" type="button"
                *ngIf="item.visible"
                (click)="insertInEditor(item.value)">
          {{item.label}}
        </button>
      </div>
    </div>
  
    <h6 style="text-align: center" *ngIf="mode=='organization'">Les coordonnées d'accès à la plateforme de Eventizer
      relatives à l'organization seront ajoutés automatiquement à la fin du mail.</h6>
  
  </div>
  <div style="text-align: center; margin-top: 20px">
    <button class="btn btn-lg btn-success" (click)="saveMail()">Enregistrer</button>
  </div>
