import { environment } from '../../../environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subscription } from 'rxjs';
import { Admin } from 'src/app/shared/models/admin/admin';
import { AdminService } from 'src/app/shared/services/admin.service';
import { ClientService } from 'src/app/shared/services/client.service';
import { ParticipantService } from 'src/app/shared/services/participant.service';
import { DatatablesUtils } from "src/app/shared/datatable-utils";
import { Utils } from "src/app/shared/utils/utils";
import { TranslateService } from '@ngx-translate/core';

declare var jQuery: any;

@Component({
  selector: 'app-list-organizers',
  templateUrl: './list-organizers.component.html',
  styleUrls: ['./list-organizers.component.css']
})
export class ListOrganizersComponent implements OnInit {
  clients: any[] = [];
  busy: Subscription;
  dtOptions: any = {};
  perPage: number = 9;
  page: number = 1;
  dtElement: DataTableDirective;
  @ViewChild(DataTableDirective, { static: true })
  tri: string;
  order: string;
  ready:boolean=false;

  constructor(public clientService: ClientService, private router: Router,private translate: TranslateService) { }

  ngOnInit() {
    this.getallclient();
  }

  getallclient()
  {
    const that = this;
    this.dtOptions = {
      dom: "['<l>', Bfrtip']",
      buttons: ["colvis"],
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: true,
      aLengthMenu: [
        [5, 10, 25, 50, 100000],
        [5, 10, 25, 50, "All"],
      ],
      ajax: (dataTablesParameters: any, callback) => {
        const page = (dataTablesParameters.start / dataTablesParameters.length + 1);
        const perPage = dataTablesParameters.length;
        const search = dataTablesParameters.search.value;

        that.busy = that.clientService
          .getListClients(perPage, page, search)
          .subscribe(
            (data: any) => {
              console.log(data);
              this.clients = data.data;
              callback({
                recordsTotal: data.to,
                recordsFiltered: data.total,
                data: that.clients,
              });
            },
            (error) => { }
          );
      },
      columns: that.getColumns(),
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        jQuery(".action-btn", row).unbind("click");
        jQuery(".action-btn", row).bind("click", (event) => {
          const actionId = parseInt($(event.target).attr("action-id"), 0);
          this.goToAction(actionId, data);
        });
      },
    };
  }

  getColumns() {
    const columns = [
      { data: "company_name", visible: true, title: "Nom de l'Entreprise" },
      { data: "director_name", visible: true, title: "Nom du dirigeant" },
      { data: "phone", visible: true, title: "Téléphone" },
      { data: "email", visible: true, title: "Email" },
      { data: "address", visible: true, title: "Adresse" },
      { data: "activity_sector", visible: true, title: "Secteur d'activité" },
      { data: "siret", visible: true, title: "Siret" },
      /* { data: "idcc", visible: true, title: "IDCC" },
      { data: "code_naf", visible: true, title: "Code NAF" },
      { data: "creation_date", visible: true, title: "Date de création" },
      { data: "urssaf_identifiant", visible: true, title: "URSSAF Identifiant" },
      { data: "impots_identifiant", visible: true, title: "IMPOTS Identifiant" },
      { data: "retraite_identifiant", visible: true, title: "RETRAITE Identifiant" },
      { data: "prevoyance_identifiant", visible: true, title: "PREVOYANCE Identifiant" },
      { data: "mutuelle_identifiant", visible: true, title: "MUTUELLE Identifiant" },*/
      {
        data: "actions",
        visible: true,
        render: function () {
          return DatatablesUtils.renderActionsClientBtn();
        },
      },
    ];
    return columns;
  }

  private goToAction(actionId: number, data) {
    switch (actionId) {
      case 1: {
        this.router.navigate(["/admins/" + data.id + "/modify-client"]);
        break;
      }
      case 2: {
        this.removeClient(data.id);
        break;
      }
    }
  }


  removeClient(client_id) {
    this.busy = this.clientService.removeClientById(client_id).subscribe(
      (data) => {
        
        Utils.sweetAlert('success', 'Succées', 'Suppression client avec succées');
        setTimeout(() => {
          window.location.reload();
        }, 2000); // 2000 milliseconds = 2 seconds
        
      }, error => {
        Utils.sweetAlert('error', 'Erreur', 'Erreur lors de la suppression');
      });
  }
    
    
  

      
}