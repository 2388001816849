import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Utils} from "../../shared/utils/utils";
import {HttpHeaders} from "@angular/common/http";
import {AdminService} from "../../shared/services/admin.service";
import {MailService} from "../../shared/services/mail.service";
import {MailTypeAdmin} from "../../shared/models/Mail_Type_Admin";
import {MailAdmin} from "../../shared/models/Mail_Admin";

declare var swal: any;
declare var jQuery: any;

@Component({
  selector: 'app-mail-editor',
  templateUrl: './mail-editor.component.html',
  styleUrls: ['./mail-editor.component.css']
})
export class MailEditorComponent implements OnInit {
  editor: any;
  template: string = null;
  mode: string = "";
  mailAdminId: number;
  object: string = null;
  mailTypeAdminId: number;

  params: ParamMail[] = [];
  mailTypeAdmin: MailTypeAdmin;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private adminService: AdminService,
              private mailService: MailService) {
  }

  ngOnInit() {
    let base = this;
    this.mailTypeAdminId = parseInt(this.route.snapshot.paramMap.get('mailTypeAdminId'), 0);
    this.mailAdminId = parseInt(this.route.snapshot.paramMap.get('mailAdminId'), 0);
    this.loadParamMail();

    this.loadMail();
  }

  /*initialiser l'editeur, et changer la façon avec la quelle il gère l'upload image*/
  onEditorCreated(editorInstance) {
    this.editor = editorInstance;
    const toolbar = this.editor.getModule('toolbar');
    toolbar.addHandler('image', this.imageHandler);
  }

  //fonction upload image

  imageHandler = (image, callback) => {
    const input = <HTMLInputElement>document.getElementById('fileInputField');
    document.getElementById('fileInputField').onchange = () => {
      let file: File;
      file = input.files[0];
      const formData = new FormData();
      formData.append('image', input.files[0], input.files[0].name);
      this.adminService.uploadImageMail(formData).subscribe(
        (res) => {
          const range = this.editor.getSelection();
          const img = '<img src="' + res.link + '" />';
          this.editor.clipboard.dangerouslyPasteHTML(range.index, img, "user");
        }
      )
    };

    input.click();
  }

  loadMail() {
      this.mailService.getMailAdminByMailTypeAdminId(this.mailTypeAdminId)
        .subscribe(
          (data:MailAdmin) => {
            if (data){
              this.object = data.object;
              this.template = data.template;
              this.mailAdminId = data.mail_admin_id;
              this.mailTypeAdmin = data.type;
            }
          },
          (error) => {
            this.router.navigateByUrl('/');
          }
        );
  }

  loadParamMail() {
    this.params = [
      {
        label: 'Nom du participant',
        value: '{{$user->last_name}}',
        visible: true
      },
      {
        label: 'Prénom du participant',
        value: '{{$user->first_name}}',
        visible: true
      },
      {
        label: 'Mr./Mme.',
        value: '{{$user->gender}}',
        visible: this.mode !== 'organization'
      },
      {
        label: 'Nom admin',
        value: '{{$admin->name}}',
        visible: true
      },
      {
        label: 'Email admin',
        value: '{{$admin->email}}',
        visible: true
      },
      {
        label: 'Password admin',
        value: '{{$admin->passwordDecrypt}}',
        visible: true
      },
      {
        label: "Eventizer BackOffice",
        value: "<{{$linkBackOffice}}",
        visible: true,
      },
      {
        label: "Lien d'activation",
        value: "<{{$activationLink}}",
        visible: true
      },
      {
        label: 'Lien de paiement',
        value: '<{{$paymentLink}}',
        visible: true
      },
      {
        label: 'Nom contact',
        value: '{{$contact->user_name}}',
        visible: true
      },
      {
        label: 'Contact email',
        value: '{{$contact->email}}',
        visible: true
      },
      {
        label: 'Téléphone de contact',
        value: '{{$contact->mobile}}',
        visible: true
      }
    ]
  }


  saveMail() {
    let base = this;
    if (!this.template || !this.object) {
      Utils.sweetAlert("warning", "Attention!", "Vérifier que l'objet ainsi que le contenu du mail ne sont pas vides!");
    } else {
      this.adminService.saveMail(this.object, this.template, this.mailTypeAdminId, this.mailAdminId).subscribe(data => {
        swal({title: 'Succès', text: 'Changements effectués avec succès', type: 'success'})
          .then(() => {
            if (this.mailTypeAdminId === 5) {
              base.router.navigateByUrl("/manage-mail/personalize");
            } else {
              base.router.navigateByUrl("/manage-mail/list");
            }
          });
      });
    }
  }

  insertInEditor(text: string) {
    const selection = this.editor.getSelection(true);
    if (text.charAt(0) === '<') {
      this.editor.insertText(selection.index, 'Link', 'link', text.substring(1), "user");
    } else {
      this.editor.insertText(selection.index, text, "user");
    }
    this.editor.setSelection(selection.index + text.length, 0, "user");
  }
}

export class ParamMail {
  label: string;
  visible: boolean;
  value: string;
}
